import React, { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';

import { createArr } from '../../helpers/functions';
import { Modal } from '../components/modal';
import Form from '../components/application-form';
import Accordion from '../components/accordion';
import SectionTitle from '../components/section-title';
import NavigationBar from '../components/navigation-bar';
import {
  USERS_REVIEW_LISTS,
  HEADER_SLIDER_CONFIG,
  USERS_REVIEW_SLIDER_CONFIG,
  REPAIR_SLIDER_CONFIG
} from '../data/slider-config';

import MAP_SVG from '../../assets/img/map.svg';
import ARROW from '../../assets/icon_arrow.svg';
import c1 from '../../assets/img/sl_BMW_X3_before.png';
import c2 from '../../assets/img/sl_BMW_X3_after.png';
import c3 from '../../assets/img/sl_mercedes_before.png';
import c4 from '../../assets/img/sl_mercedes_after.png';
import c5 from '../../assets/img/sl_tesla_before.png';
import c6 from '../../assets/img/sl_tesla_after.png';
import c7 from '../../assets/img/sl_toyota_before.png';
import c8 from '../../assets/img/sl_toyota_after.png';

import Polcar from '../../assets/img/partner_polcar.png';
import SLine from '../../assets/img/partner_s-line.png';
import BossAuto from '../../assets/img/partner_bossauto.png';

const PageMain = ({ t }) => {
  const headerSlider = useRef(null);
  const workExamplesSlider = useRef(null);
  const [showModal, toggleModal] = useState(false);
  const [activeElementId, setActiveList] = useState('');
  const resetActiveService = () => {
    setActiveList('');
  };
  const next = (slider) => {
    slider.current.slickNext();
  };
  const prev = (slider) => {
    slider.current.slickPrev();
  };

  return (
    <div className="flex-100 column">
      <header className="flex-100 column">
        <NavigationBar />
        <div className="header-slider column">
          <Slider {...HEADER_SLIDER_CONFIG} ref={headerSlider}>
            {
              createArr(4).map((s) => (
                <div className="slider-item" id={t(`main-page:headerSlider.${s}.id`)} key={s}>
                  <div className="content">
                    <div className="bg column">
                      <SectionTitle title={`main-page:headerSlider.${s}.title`} />
                      <p className="slider-item-description section-description">
                        {t(`main-page:headerSlider.${s}.description`)}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            }
          </Slider>
          <div
            className="slider-arrow left"
            aria-hidden
            onClick={() => prev(headerSlider)}
          >
            <img src={ARROW} alt="" />
          </div>
          <div
            className="slider-arrow right"
            aria-hidden
            onClick={() => next(headerSlider)}
          >
            <img src={ARROW} alt="" />
          </div>
        </div>
      </header>
      <main>
        <section className="spare-parts column layout-align-right">
          <div className="content flex column layout-align-start-center h-100">
            <SectionTitle title="main-page:spareParts.title" />
            <p className="section-description padding-none">{t('main-page:spareParts.description')}</p>
            <button
              type="button"
              className="white-button button"
              onClick={() => toggleModal(true)}
            >
              {t('ctaButton')}
            </button>
          </div>
        </section>

        <section className="services-list" onMouseLeave={resetActiveService} onBlur={() => null}>
          <div className="content">
            <SectionTitle title="main-page:servicesList.title" />
            <div className="services">
              <div className="left">
                {
                  createArr(2).map((s, i, list) => (
                    <Accordion
                      setActiveList={setActiveList}
                      activeElementId={activeElementId}
                      list={list}
                      id={`accordion-services-left${i}`}
                      title={t(`main-page:servicesList.items.${s}.title`)}
                    >
                      {
                        createArr(t(`main-page:servicesList.items.${s}.list`, { returnObjects: true }).length)
                          .map((l) => (
                            <li key={l}>{t(`main-page:servicesList.items.${s}.list.${l}`)}</li>
                          ))
                      }
                    </Accordion>
                  ))
                }
              </div>
              <div className="right">
                {
                    createArr(5).slice(2, 5).map((s, i, list) => (
                      <Accordion
                        setActiveList={setActiveList}
                        activeElementId={activeElementId}
                        list={list}
                        id={`accordion-services-right${i}`}
                        title={t(`main-page:servicesList.items.${s}.title`)}
                      >
                        {
                          createArr(t(`main-page:servicesList.items.${s}.list`, { returnObjects: true }).length)
                            .map((l) => (
                              <li key={l}>{t(`main-page:servicesList.items.${s}.list.${l}`)}</li>
                            ))
                        }
                      </Accordion>
                    ))
                  }
              </div>
            </div>
          </div>
        </section>
        <section className="service-defecting">
          <div className="content column">
            <SectionTitle title="main-page:serviceDefecting.title" />
            {
              createArr(2).map((i) => (
                <div className="service-item" key={i}>
                  <div>
                    <p>
                      {t(`main-page:serviceDefecting.items.${i}.name`).map((item) => (
                        <>
                          {item}
                          <br />
                        </>
                      ))}
                    </p>
                  </div>
                  <p>{t(`main-page:serviceDefecting.items.${i}.price`)}</p>
                </div>
              ))
            }
          </div>
        </section>
        <section className="about-us">
          <div className="content">
            <SectionTitle title="main-page:aboutUs.title" />
            <p className="section-description">{t('main-page:aboutUs.description.0')}</p>
            <p className="section-description">{t('main-page:aboutUs.description.1')}</p>
          </div>
        </section>
        <section className="our-services">
          <div className="content">
            <SectionTitle title="main-page:ourServices.title" />
            <img src={MAP_SVG} alt="map" />
          </div>
        </section>
        <section className="work-examples">
          <SectionTitle title="main-page:workExamples.title" />
          <div className="work-examples-slider">
            <Slider {...REPAIR_SLIDER_CONFIG} ref={workExamplesSlider}>
              {
                [[c1, c2], [c3, c4], [c5, c6], [c7, c8]].map(([before, after], i) => (
                  <div className="slider-content" key={i}>
                    <img src={before} className="img" alt="" />
                    <img src={after} className="img" alt="" />
                  </div>
                ))
              }
            </Slider>
            <div
              className="slider-arrow left"
              aria-hidden
              onClick={() => prev(workExamplesSlider)}
            >
              <img src={ARROW} alt="" />
            </div>
            <div
              className="slider-arrow right"
              aria-hidden
              onClick={() => next(workExamplesSlider)}
            >
              <img src={ARROW} alt="" />
            </div>
          </div>
        </section>
        <section className="reviews">
          <SectionTitle title="main-page:reviews.title" />
          <div className="review-slider">
            <Slider {...USERS_REVIEW_SLIDER_CONFIG}>
              {
                USERS_REVIEW_LISTS
                  .map((list) => (
                    <div className="review-slider-sections">
                      <div className="left">
                        {
                          list.slice(0, 2).map((item) => (
                            <div className="review-message">
                              <p className="text">{item.text}</p>
                              <div className="author">{item.author}</div>
                            </div>
                          ))
                        }
                      </div>
                      <hr className="divider" />
                      <div className="right">
                        {
                          list.slice(2).map((item) => (
                            <div className="review-message">
                              <p className="text">{item.text}</p>
                              <div className="author">{item.author}</div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  ))
              }
            </Slider>
          </div>
        </section>
        <section className="our-partners">
          <div className="content">
            <SectionTitle title="main-page:partners.title" />
            <div className="partners-list">
              <img src={Polcar} alt="Polcar" />
              <img src={SLine} alt="S-Line" />
              <img src={BossAuto} alt="BossAuto" />
            </div>
          </div>
        </section>
        <section className="contacts">
          <div className="content column">
            <SectionTitle title="main-page:contacts.title" />
            <div className="contacts-list">
              <div className="contact">
                <div className="number">
                  <a href="tel:+380 93 640 35 94">+380 93 640 35 94</a>
                </div>
                <div className="name"> - Прийом Авто на СТО</div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <p>{t('footer.footerText')}</p>
      </footer>
      {
        showModal && (
          <Modal
            toggleModal={toggleModal}
            open={showModal}
          >
            <Form />
          </Modal>
        )
      }
    </div>
  );
};

export default withTranslation()(PageMain);
